import React from 'react'
import BookSelector from './BookSelector'
import { Button, Icon } from 'semantic-ui-react'

// onClick={() => {
// 	const ls = [/*'breadthfirst', 'klay',*/ 'dagre' /*'grid'*/]
// 	const l = ls[Math.floor(Math.random() * ls.length)]
// 	setLayout(l)
// }}>
// <div style={{ display: 'flex', flexDirection: 'vertical', alignItems: 'center', justifyContent: 'center' }}>
// <BookSelector />

const style = {
	marginTop: '5px',
	marginBottom: '5px',
}

const flex = {
	minWidth: '150px',
	display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}
const Commands = (props) => {
	return (
		<div style={flex}>
			<Button style={style} primary onClick={()=>{
				props.onFit()
			}}>
				Fit Graph
			</Button>
			<Button
				style={style}
				primary
				onClick={() => {
					props.onSetLayout('dagre')
				}}>
				Change Layout
			</Button>
		</div>
	)
}
export default Commands
