const createNode = (id) => {
    return {
        data: {
            id: id,
            label: '#' + id,
            end: undefined,
            iap: undefined,
        },
    }
}

const createNodes = number => {
    const nodes = []
    for (var i = 0; i < number - 1; i++) {
        nodes[i] = createNode(i)
    }
    return nodes
}

const createEdge = (nodes) => 
{
    const randomSourceId = nodes[Math.floor(Math.random()*nodes.length)].data.id
    const randomTargetId = nodes[Math.floor(Math.random()*nodes.length)].data.id
    return {
        data:{
            source: randomSourceId,
            target: randomTargetId,
            label: '',
        }
    }
}

const createEdges = (number, nodes) => {
    const edges = []
    for (var i = 0; i < number - 1; i++) {
        edges[i] = createEdge(nodes)
    }
    return edges
}

const nodes = createNodes(100)
const edges = createEdges(100, nodes)
// const elements = CytoscapeComponent.normalizeElements({
//     nodes: nodes,
//     edges: edges,
// })

const elements = {
    nodes: nodes,
    edges: edges,
}

module.exports = elements
