// https://js.cytoscape.org/#selectors/data
const stylesheet = [
    {
        selector: 'node',
        style: {
            height: 80,
            width: 80,
            // 'background-fit': 'cover',
            // 'background-color': 'red',
            'border-color': '(red, blue, green)',
            'border-width': 3,
            'border-opacity': 0.5,
            label: 'data(label)',
            // 'background-fill': 'linear-gradient',
            //'background-gradient-direction': 'to-bottom-left',
            // 'background-gradient-stop-colors': 'red blue',
            // 'background-gradient-stops-positions': '30 70',
            // 'background-image': () => 'https://picsum.photos/100/100'
        },
    },
    {
        selector: 'edge',
        style: {
            'curve-style': 'bezier',
            width: 3,
            'target-arrow-shape': 'triangle',
            'line-color': 'grey',
            'target-arrow-color': 'grey',
            label: 'data(label)', // maps to data.label
        },
    },
    {
        selector: '#1', //# serve a identiricare l'id del nodo NON e' il modo di IM di indicare i paragrafi
        style: {
            'background-color': 'blue',
            'border-width': 10,
            'border-opacity': 0.8,
        },
    },
    {
        selector: '[?end]', // definito e diverso da ""
        style: {
            // 'background-image': 'https://picsum.photos/100/100',
            'border-opacity': 0.8,
            'border-width': 10,
            'background-color': function (x) {
                // console.log('END',x.id())
                let color = '#000'
                if (!x.data('end')) {
                    // console.log('INNER')
                    return color
                }
                // console.log('OUTER')
                color = x.data('end') === 'positive' ? 'green' : 'red'
                return color
            },
        },
    },
    // {
    //     selector: '[?iap]',

    //     style: {
    //         // 'background-image': 'https://picsum.photos/100/100',
    //         'border-opacity': 0.8,
    //         'border-width': 10,
    //         'background-color': function (x) {
    //             let color = '#000'
    //             if (!x.data('iap')) {
    //                 return color
    //             }
    //             color = x.data('iap') === 'libera' ? 'orange' : 'gold'
    //             return color
    //         },
    //     },
    // },
    // {
    //     selector: '#2',
    //     style: {
    //         // 'background-image': 'https://live.staticflickr.com/7272/7633179468_3e19e45a0c_b.jpg',
    //     },
    // },
    // {
    //     selector: '#3',
    //     style: {
    //         // 'background-image': 'https://live.staticflickr.com/8316/8003798443_32d01257c8_b.jpg',
    //     },
    // },
    // {
    //     selector: '#4',
    //     style: {
    //         // 'background-image': 'https://live.staticflickr.com/5109/5817854163_eaccd688f5_b.jpg',
    //     },
    // },
]

export default stylesheet
