import React, { useState } from 'react'

import 'semantic-ui-css/semantic.min.css'

import Graph from './graph/Graph'
import InpuFileGG from './InpuFileGG'

import { Grid, Image, Message, Divider } from 'semantic-ui-react'
import { Container } from 'semantic-ui-react'

const banner = {
    // border: '1px solid red',
    backgroundImage: 'url(/images/banner-only.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    height: '200px',
    width: '100%',
}

const DEBUG = false

const App = () => {
    const [tree, setTree] = useState(null)
    // const [layout, setLayout] = useState('grid')
    // const [triggerFit, setTriggerFit] = useState(false)

    const handleFileInput = (_tree) => {
        setTree(_tree)
    }

    const Upload = (
        <Container style={{ textAlign: 'center' }}>
            <div>Upload an Infinity Book file</div>
            <InpuFileGG onData={handleFileInput} />
        </Container>
    )

    const Graphx = (
        // <Container>
        <Graph DEBUG={DEBUG} tree={tree} />
        // </Container>
    )

    // const footer = {
    //   position: 'fixed',
    //   left: 0,
    //   bottom: 0,
    //   width: '100%',
    //   backgroundColor: 'red',
    //   color: 'white',
    //   textAlign: 'center',
    // }

    return (
        <Grid style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
            <Grid.Row style={{marginTop:'50px'}}>
                <Grid.Column style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent:'center' }} width={3} >
                    <Image centered size="tiny" src="/logo512.png" />
                    <a href="https://www.infinitymundi.com">www.infinitymundi.com</a>
                    <a href="https://www.infinitymundi.it">www.infinitymundi.it</a>
                </Grid.Column>
                <Grid.Column width={13}>
                    
                    <Message info>
                        <Message.Header>Welcome to the Infinity Visualizer!</Message.Header>
                        <p>
                            This is an <b>Alpha</b> version, many things have to be done! If you
                            have comments on how to improve it, tell us{' '}
                            <a href="mailto:infinitymundi.publishing@gmail.com">clicking here!</a>
                        </p>
                    </Message>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ flex: 1 }}>
                <Grid.Column width={16}>{!tree && !DEBUG ? Upload : Graphx}</Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Divider />
                <div>
                    <Image src="/logo192.png" size="mini" spaced/>
                    Infinity Mundi, Infinity Book, Infinity Creator, Infinity Validator Copyright ©
                    2020 - All rights reserved.
                    <Image src="/logo192.png" size="mini" spaced/>
                </div>
            </Grid.Row>
        </Grid>
    )

    // if (!tree && !DEBUG)
    //     return (
    //         <Container style={{ textAlign: 'center' }}>
    //             <div>Upload a file</div>
    //             <InpuFileGG onData={handleFileInput} />
    //         </Container>
    //     )
    // return (
    //     <Container>
    //         <Graph DEBUG={DEBUG} ree={tree} />
    //     </Container>
    // )
}
export default App
