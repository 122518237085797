const fixed = require('./fixed')
const generated = require('./generated')
const sottoterra = require('./sottoterra')
const anima = require('./anima')
const fortezzaeuropa = require('./fortezzaeuropa')

// const chooseElements = (id) => {
// 	if (id === 'fixed') {
// 		return fixed
// 	}
// 	if (id === 'generated') {
// 		return generated
// 	}
// 	if (id === 'sottoterra') {
// 		return sottoterra
// 	}
// 	throw new Error('id not recognized')
// }
module.exports = {fixed, generated, sottoterra, anima, fortezzaeuropa}
