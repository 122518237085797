const elements = {
    nodes: [
        { id: '1', label: '#paragraph-1', end: undefined, iap: undefined },
        { id: '2', label: '#paragraph-2', end: undefined, iap: undefined },
        { id: '3', label: '#paragraph-3', end: undefined, iap: 'libera' },
        { id: '4', label: '#paragraph-4', end: undefined, iap: undefined },
        { id: '5', label: '#paragraph-5', end: 'positive', iap: undefined },
        { id: '6', label: '#paragraph-6', end: undefined, iap: undefined },
        { id: '7', label: '#paragraph-7', end: undefined, iap: 'bloccante' },
        { id: '8', label: '#paragraph-8', end: 'negative', iap: undefined },
        { id: '9', label: '#paragraph-9', end: 'negative', iap: undefined },
        { id: '10', label: '#paragraph-10', end: 'negative', iap: undefined },
        { id: '11', label: '#paragraph-11', end: 'negative', iap: undefined },
        { id: '12', label: '#paragraph-12', end: 'negative', iap: undefined },
        { id: '13', label: '#paragraph-13', end: 'negative', iap: undefined },
        { id: '14', label: '#paragraph-14', end: 'negative', iap: undefined },
    ],
    edges: [
        { source: '1', target: '2', label: 'Edge from Node1 to Node2' },
        { source: '2', target: '1', label: 'Edge from Node1 to Node2' },
        { source: '2', target: '3', label: 'Edge from Node1 to No' },
        { source: '2', target: '4', label: '' },
        { source: '4', target: '5', label: '' },
        { source: '4', target: '1', label: '' },
        { source: '4', target: '6', label: '' },
        { source: '3', target: '7', label: '' },
        { source: '7', target: '8', label: '' },
        { source: '4', target: '11', label: '' },
        { source: '12', target: '13', label: '' },
        { source: '1', target: '8', label: '' },
        { source: '13', target: '14', label: '' },
    ],
}

// const elements = {
//     nodes: [
//         { data: { id: '1', label: '#paragraph-1', end: undefined, iap: undefined } },
//         { data: { id: '2', label: '#paragraph-2', end: undefined, iap: undefined } },
//         { data: { id: '3', label: '#paragraph-3', end: undefined, iap: 'libera' } },
//         { data: { id: '4', label: '#paragraph-4', end: undefined, iap: undefined } },
//         { data: { id: '5', label: '#paragraph-5', end: 'positive', iap: undefined } },
//         { data: { id: '6', label: '#paragraph-6', end: undefined, iap: undefined } },
//         { data: { id: '7', label: '#paragraph-7', end: undefined, iap: 'bloccante' } },
//         { data: { id: '8', label: '#paragraph-8', end: 'negative', iap: undefined } },
//         { data: { id: '9', label: '#paragraph-9', end: 'negative', iap: undefined } },
//         { data: { id: '10', label: '#paragraph-10', end: 'negative', iap: undefined } },
//         { data: { id: '11', label: '#paragraph-11', end: 'negative', iap: undefined } },
//         { data: { id: '12', label: '#paragraph-12', end: 'negative', iap: undefined } },
//         { data: { id: '13', label: '#paragraph-13', end: 'negative', iap: undefined } },
//         { data: { id: '14', label: '#paragraph-14', end: 'negative', iap: undefined } },
//     ],
//     edges: [
//         {
//             data: { source: '1', target: '2', label: 'Edge from Node1 to Node2' },
//         },
//         {
//             data: { source: '2', target: '1', label: 'Edge from Node1 to Node2' },
//         },
//         {
//             data: { source: '2', target: '3', label: 'Edge from Node1 to No' },
//         },
//         { data: { source: '2', target: '4', label: '' } },
//         { data: { source: '4', target: '5', label: '' } },
//         { data: { source: '4', target: '1', label: '' } },
//         { data: { source: '4', target: '6', label: '' } },
//         { data: { source: '3', target: '7', label: '' } },
//         { data: { source: '7', target: '8', label: '' } },
//         { data: { source: '4', target: '11', label: '' } },
//         { data: { source: '12', target: '13', label: '' } },
//         { data: { source: '1', target: '8', label: '' } },
//         { data: { source: '13', target: '14', label: '' } },

//     ],
// }

module.exports = elements
