import React, { useState } from 'react'

import CytoscapeComponent from 'react-cytoscapejs'
import processTree from '../utils/process-tree'
import stylesheet from './stylesheet'
import setAndReturnLayoutName from './layout'
import Commands from './Commands'
import { Message } from 'semantic-ui-react'
import Sticky from './Sticky'

import fixtures from '../fixtures'
processTree(fixtures.anima)
const elements = fixtures.anima
// console.log(elements)

let myCyRef

const Graph = ({ DEBUG, tree }) => {
    const [layout, setLayout] = useState('breadthfirst')
    
    const onFitHandler = () => {
        myCyRef.fit()
    }

    const onSetLayoutHandler = (layout) => {
        setLayout(layout)
    }

    // return <Sticky/>
    let treeOrElements = tree || elements

    // console.log(elements)
    // console.log('VS')
    // console.log(tree)
    if (!treeOrElements) return 'EMPTY'
    return (
        <div
            style={{
                marginLeft: '50px',
                marginRight: '50px',
                display: 'flex',
                flexDirection: 'row',

                // alignItems: 'center',
                // justifyContent: 'space-between',
            }}>
            <Commands onFit={onFitHandler} onSetLayout={onSetLayoutHandler} />
            <CytoscapeComponent
                cy={(cy) => {
                       myCyRef = cy
                    }}
                style={{
                    width: '100%',
                    height: '100vh',
                    border: '2px solid black',
                }}
                layout={setAndReturnLayoutName(layout)}
                elements={CytoscapeComponent.normalizeElements(treeOrElements)}
                stylesheet={stylesheet}
            />
            
        </div>
    )
}

export default Graph
