import React from 'react'
import { Button } from 'semantic-ui-react'
import { Input } from 'semantic-ui-react'
import processTree from './utils/process-tree'


const InpuFileGG = ({onData}) => {
    const handleFile = (e) => {
        const files = e.target.files
        const file = files[0]
        const reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = evt => {
            const data = JSON.parse(evt.target.result)
            // console.log(data)
            processTree(data)
            onData(data)
        }
    }
    return <Input type="file" onChange={handleFile} as={Button} />
}

export default InpuFileGG
