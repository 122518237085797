const elements = {
   "nodes":[
      {
         "id":"1",
         "label":"1",
         "end":"",
         "iap":""
      },
      {
         "id":"2",
         "label":"2",
         "end":"",
         "iap":""
      },
      {
         "id":"3",
         "label":"3",
         "end":"",
         "iap":""
      },
      {
         "id":"trasferimento",
         "label":"trasferimento",
         "end":"",
         "iap":""
      },
      {
         "id":"lenta",
         "label":"lenta",
         "end":"",
         "iap":""
      },
      {
         "id":"suicidio",
         "label":"suicidio",
         "end":"",
         "iap":""
      },
      {
         "id":"suicidio-ok",
         "label":"suicidio-ok",
         "end":"negative",
         "iap":""
      },
      {
         "id":"suicidio-non-ok",
         "label":"suicidio-non-ok",
         "end":"",
         "iap":""
      },
      {
         "id":"agenti-demicorp",
         "label":"agenti-demicorp",
         "end":"",
         "iap":""
      },
      {
         "id":"veloce",
         "label":"veloce",
         "end":"",
         "iap":""
      },
      {
         "id":"inizio-trasferimento",
         "label":"inizio-trasferimento",
         "end":"",
         "iap":""
      },
      {
         "id":"concludi-procedura",
         "label":"concludi-procedura",
         "end":"",
         "iap":""
      },
      {
         "id":"electra-success-pain",
         "label":"electra-success-pain",
         "end":"",
         "iap":""
      },
      {
         "id":"electra-fail-pain",
         "label":"electra-fail-pain",
         "end":"",
         "iap":""
      },
      {
         "id":"electra",
         "label":"electra",
         "end":"",
         "iap":""
      },
      {
         "id":"electra-2",
         "label":"electra-2",
         "end":"",
         "iap":""
      },
      {
         "id":"mostra-successo",
         "label":"mostra-successo",
         "end":"",
         "iap":""
      },
      {
         "id":"confronto-electra",
         "label":"confronto-electra",
         "end":"",
         "iap":""
      },
      {
         "id":"seduci-success",
         "label":"seduci-success",
         "end":"",
         "iap":""
      },
      {
         "id":"seduci-fail",
         "label":"seduci-fail",
         "end":"",
         "iap":""
      },
      {
         "id":"capelli",
         "label":"capelli",
         "end":"",
         "iap":""
      },
      {
         "id":"sono-assistente-success",
         "label":"sono-assistente-success",
         "end":"",
         "iap":""
      },
      {
         "id":"ginocchiata",
         "label":"ginocchiata",
         "end":"",
         "iap":""
      },
      {
         "id":"colpo-alla-testa",
         "label":"colpo-alla-testa",
         "end":"",
         "iap":""
      },
      {
         "id":"confronto",
         "label":"confronto",
         "end":"",
         "iap":""
      },
      {
         "id":"spara",
         "label":"spara",
         "end":"",
         "iap":""
      },
      {
         "id":"achievement-killer",
         "label":"achievement-killer",
         "end":"",
         "iap":""
      },
      {
         "id":"verità",
         "label":"verità",
         "end":"",
         "iap":""
      },
      {
         "id":"arresto",
         "label":"arresto",
         "end":"negative",
         "iap":""
      },
      {
         "id":"bugia",
         "label":"bugia",
         "end":"",
         "iap":""
      },
      {
         "id":"colpo-al-viso",
         "label":"colpo-al-viso",
         "end":"",
         "iap":""
      },
      {
         "id":"morte-Health",
         "label":"morte-Health",
         "end":"negative",
         "iap":""
      },
      {
         "id":"bugia-3",
         "label":"bugia-3",
         "end":"",
         "iap":""
      },
      {
         "id":"nascondiglio",
         "label":"nascondiglio",
         "end":"",
         "iap":""
      },
      {
         "id":"nascondiglio-armadio",
         "label":"nascondiglio-armadio",
         "end":"",
         "iap":""
      },
      {
         "id":"nascondiglio-capsula",
         "label":"nascondiglio-capsula",
         "end":"",
         "iap":""
      },
      {
         "id":"nascondiglio-scoperto",
         "label":"nascondiglio-scoperto",
         "end":"negative",
         "iap":""
      },
      {
         "id":"fuga",
         "label":"fuga",
         "end":"",
         "iap":""
      },
      {
         "id":"fuga-principale",
         "label":"fuga-principale",
         "end":"",
         "iap":""
      },
      {
         "id":"morte-sparato",
         "label":"morte-sparato",
         "end":"negative",
         "iap":""
      },
      {
         "id":"fuga-fuori",
         "label":"fuga-fuori",
         "end":"",
         "iap":""
      },
      {
         "id":"imboscata",
         "label":"imboscata",
         "end":"",
         "iap":""
      },
      {
         "id":"imboscata-ginocchio",
         "label":"imboscata-ginocchio",
         "end":"",
         "iap":""
      },
      {
         "id":"imboscata-morte",
         "label":"imboscata-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"imboscata-uccidi",
         "label":"imboscata-uccidi",
         "end":"",
         "iap":""
      },
      {
         "id":"imboscata-perquisire",
         "label":"imboscata-perquisire",
         "end":"",
         "iap":""
      },
      {
         "id":"imboscata-testa",
         "label":"imboscata-testa",
         "end":"",
         "iap":""
      },
      {
         "id":"difesa-success",
         "label":"difesa-success",
         "end":"",
         "iap":""
      },
      {
         "id":"difesa-fail",
         "label":"difesa-fail",
         "end":"",
         "iap":""
      },
      {
         "id":"morte-generica",
         "label":"morte-generica",
         "end":"negative",
         "iap":""
      },
      {
         "id":"imboscata-testa-fine",
         "label":"imboscata-testa-fine",
         "end":"negative",
         "iap":""
      },
      {
         "id":"Continue-correre",
         "label":"Continue-correre",
         "end":"positive",
         "iap":""
      },
      {
         "id":"fuga-seminterrato",
         "label":"fuga-seminterrato",
         "end":"",
         "iap":""
      },
      {
         "id":"schiva-ok",
         "label":"schiva-ok",
         "end":"",
         "iap":""
      },
      {
         "id":"schiva-non-ok",
         "label":"schiva-non-ok",
         "end":"",
         "iap":""
      },
      {
         "id":"pugno-ok",
         "label":"pugno-ok",
         "end":"",
         "iap":""
      },
      {
         "id":"colpo-non-ok",
         "label":"colpo-non-ok",
         "end":"",
         "iap":""
      },
      {
         "id":"seminterrato-vittoria",
         "label":"seminterrato-vittoria",
         "end":"",
         "iap":""
      },
      {
         "id":"fuga-tetto",
         "label":"fuga-tetto",
         "end":"",
         "iap":""
      },
      {
         "id":"tetto-sparano",
         "label":"tetto-sparano",
         "end":"",
         "iap":""
      },
      {
         "id":"tetto-scendi",
         "label":"tetto-scendi",
         "end":"",
         "iap":""
      },
      {
         "id":"tetto-salto",
         "label":"tetto-salto",
         "end":"",
         "iap":""
      },
      {
         "id":"tetto-cadi",
         "label":"tetto-cadi",
         "end":"negative",
         "iap":""
      },
      {
         "id":"tetto-calata",
         "label":"tetto-calata",
         "end":"",
         "iap":""
      },
      {
         "id":"scendere",
         "label":"scendere",
         "end":"",
         "iap":""
      },
      {
         "id":"scendere-success",
         "label":"scendere-success",
         "end":"",
         "iap":""
      },
      {
         "id":"scendere-fail",
         "label":"scendere-fail",
         "end":"negative",
         "iap":""
      },
      {
         "id":"riposare",
         "label":"riposare",
         "end":"",
         "iap":""
      },
      {
         "id":"resa",
         "label":"resa",
         "end":"negative",
         "iap":""
      },
      {
         "id":"agguato",
         "label":"agguato",
         "end":"",
         "iap":""
      },
      {
         "id":"agguato-success",
         "label":"agguato-success",
         "end":"",
         "iap":""
      },
      {
         "id":"agguato-fail",
         "label":"agguato-fail",
         "end":"",
         "iap":""
      },
      {
         "id":"agguato-morte",
         "label":"agguato-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"spari",
         "label":"spari",
         "end":"",
         "iap":""
      },
      {
         "id":"diversivo",
         "label":"diversivo",
         "end":"",
         "iap":""
      },
      {
         "id":"lancia",
         "label":"lancia",
         "end":"",
         "iap":""
      },
      {
         "id":"lancia-cespugli",
         "label":"lancia-cespugli",
         "end":"",
         "iap":""
      },
      {
         "id":"lancia-agente",
         "label":"lancia-agente",
         "end":"",
         "iap":""
      },
      {
         "id":"usa-tlc",
         "label":"usa-tlc",
         "end":"",
         "iap":""
      },
      {
         "id":"tlc-success",
         "label":"tlc-success",
         "end":"",
         "iap":""
      },
      {
         "id":"tlc-fail",
         "label":"tlc-fail",
         "end":"",
         "iap":""
      }
   ],
   "edges":[
      {
         "source":"1",
         "target":"2",
         "label":""
      },
      {
         "source":"2",
         "target":"3",
         "label":""
      },
      {
         "source":"3",
         "target":"suicidio",
         "label":""
      },
      {
         "source":"3",
         "target":"agenti-demicorp",
         "label":""
      },
      {
         "source":"3",
         "target":"trasferimento",
         "label":""
      },
      {
         "source":"trasferimento",
         "target":"lenta",
         "label":""
      },
      {
         "source":"trasferimento",
         "target":"veloce",
         "label":""
      },
      {
         "source":"lenta",
         "target":"inizio-trasferimento",
         "label":""
      },
      {
         "source":"suicidio",
         "target":"suicidio-ok",
         "label":""
      },
      {
         "source":"suicidio",
         "target":"suicidio-non-ok",
         "label":""
      },
      {
         "source":"suicidio-non-ok",
         "target":"agenti-demicorp",
         "label":""
      },
      {
         "source":"suicidio-non-ok",
         "target":"trasferimento",
         "label":""
      },
      {
         "source":"agenti-demicorp",
         "target":"confronto",
         "label":""
      },
      {
         "source":"agenti-demicorp",
         "target":"nascondiglio",
         "label":""
      },
      {
         "source":"agenti-demicorp",
         "target":"fuga",
         "label":""
      },
      {
         "source":"veloce",
         "target":"inizio-trasferimento",
         "label":""
      },
      {
         "source":"inizio-trasferimento",
         "target":"concludi-procedura",
         "label":""
      },
      {
         "source":"inizio-trasferimento",
         "target":"confronto",
         "label":""
      },
      {
         "source":"inizio-trasferimento",
         "target":"fuga",
         "label":""
      },
      {
         "source":"inizio-trasferimento",
         "target":"nascondiglio",
         "label":""
      },
      {
         "source":"concludi-procedura",
         "target":"electra-success-pain",
         "label":""
      },
      {
         "source":"concludi-procedura",
         "target":"electra-fail-pain",
         "label":""
      },
      {
         "source":"electra-success-pain",
         "target":"electra",
         "label":""
      },
      {
         "source":"electra-fail-pain",
         "target":"electra",
         "label":""
      },
      {
         "source":"electra",
         "target":"electra-2",
         "label":""
      },
      {
         "source":"electra-2",
         "target":"mostra-successo",
         "label":""
      },
      {
         "source":"electra-2",
         "target":"fuga",
         "label":""
      },
      {
         "source":"electra-2",
         "target":"nascondiglio",
         "label":""
      },
      {
         "source":"mostra-successo",
         "target":"confronto-electra",
         "label":""
      },
      {
         "source":"confronto-electra",
         "target":"seduci-success",
         "label":""
      },
      {
         "source":"confronto-electra",
         "target":"bugia-3",
         "label":""
      },
      {
         "source":"confronto-electra",
         "target":"sono-assistente-success",
         "label":""
      },
      {
         "source":"confronto-electra",
         "target":"seduci-fail",
         "label":""
      },
      {
         "source":"confronto-electra",
         "target":"colpo-al-viso",
         "label":""
      },
      {
         "source":"confronto-electra",
         "target":"arresto",
         "label":""
      },
      {
         "source":"seduci-success",
         "target":"ginocchiata",
         "label":""
      },
      {
         "source":"seduci-success",
         "target":"colpo-alla-testa",
         "label":""
      },
      {
         "source":"seduci-fail",
         "target":"capelli",
         "label":""
      },
      {
         "source":"capelli",
         "target":"fuga",
         "label":""
      },
      {
         "source":"sono-assistente-success",
         "target":"fuga",
         "label":""
      },
      {
         "source":"ginocchiata",
         "target":"fuga",
         "label":""
      },
      {
         "source":"colpo-alla-testa",
         "target":"fuga",
         "label":""
      },
      {
         "source":"confronto",
         "target":"spara",
         "label":""
      },
      {
         "source":"confronto",
         "target":"verità",
         "label":""
      },
      {
         "source":"confronto",
         "target":"bugia",
         "label":""
      },
      {
         "source":"spara",
         "target":"achievement-killer",
         "label":""
      },
      {
         "source":"achievement-killer",
         "target":"fuga",
         "label":""
      },
      {
         "source":"verità",
         "target":"arresto",
         "label":""
      },
      {
         "source":"bugia",
         "target":"arresto",
         "label":""
      },
      {
         "source":"bugia",
         "target":"colpo-al-viso",
         "label":""
      },
      {
         "source":"bugia",
         "target":"bugia-3",
         "label":""
      },
      {
         "source":"colpo-al-viso",
         "target":"spara",
         "label":""
      },
      {
         "source":"colpo-al-viso",
         "target":"fuga",
         "label":""
      },
      {
         "source":"colpo-al-viso",
         "target":"morte-Health",
         "label":""
      },
      {
         "source":"bugia-3",
         "target":"spara",
         "label":""
      },
      {
         "source":"bugia-3",
         "target":"fuga",
         "label":""
      },
      {
         "source":"nascondiglio",
         "target":"nascondiglio-armadio",
         "label":""
      },
      {
         "source":"nascondiglio",
         "target":"nascondiglio-capsula",
         "label":""
      },
      {
         "source":"nascondiglio-armadio",
         "target":"fuga",
         "label":""
      },
      {
         "source":"nascondiglio-capsula",
         "target":"nascondiglio-scoperto",
         "label":""
      },
      {
         "source":"nascondiglio-capsula",
         "target":"nascondiglio-armadio",
         "label":""
      },
      {
         "source":"fuga",
         "target":"fuga-principale",
         "label":""
      },
      {
         "source":"fuga",
         "target":"fuga-seminterrato",
         "label":""
      },
      {
         "source":"fuga",
         "target":"fuga-tetto",
         "label":""
      },
      {
         "source":"fuga-principale",
         "target":"fuga-seminterrato",
         "label":""
      },
      {
         "source":"fuga-principale",
         "target":"fuga-tetto",
         "label":""
      },
      {
         "source":"fuga-principale",
         "target":"fuga-fuori",
         "label":""
      },
      {
         "source":"fuga-principale",
         "target":"morte-sparato",
         "label":""
      },
      {
         "source":"fuga-fuori",
         "target":"imboscata",
         "label":""
      },
      {
         "source":"fuga-fuori",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"imboscata",
         "target":"imboscata-ginocchio",
         "label":""
      },
      {
         "source":"imboscata",
         "target":"imboscata-testa",
         "label":""
      },
      {
         "source":"imboscata-ginocchio",
         "target":"imboscata-uccidi",
         "label":""
      },
      {
         "source":"imboscata-ginocchio",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"imboscata-ginocchio",
         "target":"imboscata-morte",
         "label":""
      },
      {
         "source":"imboscata-uccidi",
         "target":"imboscata-perquisire",
         "label":""
      },
      {
         "source":"imboscata-uccidi",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"imboscata-perquisire",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"imboscata-testa",
         "target":"difesa-success",
         "label":""
      },
      {
         "source":"imboscata-testa",
         "target":"difesa-fail",
         "label":""
      },
      {
         "source":"imboscata-testa",
         "target":"imboscata-testa-fine",
         "label":""
      },
      {
         "source":"difesa-success",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"difesa-success",
         "target":"morte-generica",
         "label":""
      },
      {
         "source":"difesa-fail",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"difesa-fail",
         "target":"morte-generica",
         "label":""
      },
      {
         "source":"fuga-seminterrato",
         "target":"schiva-ok",
         "label":""
      },
      {
         "source":"fuga-seminterrato",
         "target":"schiva-non-ok",
         "label":""
      },
      {
         "source":"schiva-ok",
         "target":"pugno-ok",
         "label":""
      },
      {
         "source":"schiva-ok",
         "target":"colpo-non-ok",
         "label":""
      },
      {
         "source":"schiva-non-ok",
         "target":"pugno-ok",
         "label":""
      },
      {
         "source":"schiva-non-ok",
         "target":"colpo-non-ok",
         "label":""
      },
      {
         "source":"schiva-non-ok",
         "target":"morte-generica",
         "label":""
      },
      {
         "source":"pugno-ok",
         "target":"seminterrato-vittoria",
         "label":""
      },
      {
         "source":"pugno-ok",
         "target":"colpo-non-ok",
         "label":""
      },
      {
         "source":"colpo-non-ok",
         "target":"schiva-ok",
         "label":""
      },
      {
         "source":"colpo-non-ok",
         "target":"schiva-non-ok",
         "label":""
      },
      {
         "source":"seminterrato-vittoria",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"fuga-tetto",
         "target":"tetto-sparano",
         "label":""
      },
      {
         "source":"fuga-tetto",
         "target":"tetto-scendi",
         "label":""
      },
      {
         "source":"tetto-sparano",
         "target":"tetto-scendi",
         "label":""
      },
      {
         "source":"tetto-scendi",
         "target":"tetto-salto",
         "label":""
      },
      {
         "source":"tetto-scendi",
         "target":"tetto-calata",
         "label":""
      },
      {
         "source":"tetto-salto",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"tetto-salto",
         "target":"tetto-cadi",
         "label":""
      },
      {
         "source":"tetto-calata",
         "target":"scendere",
         "label":""
      },
      {
         "source":"tetto-calata",
         "target":"riposare",
         "label":""
      },
      {
         "source":"scendere",
         "target":"scendere-success",
         "label":""
      },
      {
         "source":"scendere",
         "target":"scendere-fail",
         "label":""
      },
      {
         "source":"scendere-success",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"riposare",
         "target":"resa",
         "label":""
      },
      {
         "source":"riposare",
         "target":"agguato",
         "label":""
      },
      {
         "source":"riposare",
         "target":"spari",
         "label":""
      },
      {
         "source":"riposare",
         "target":"diversivo",
         "label":""
      },
      {
         "source":"agguato",
         "target":"agguato-success",
         "label":""
      },
      {
         "source":"agguato",
         "target":"agguato-fail",
         "label":""
      },
      {
         "source":"agguato-success",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"agguato-fail",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"agguato-fail",
         "target":"agguato-morte",
         "label":""
      },
      {
         "source":"spari",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"diversivo",
         "target":"lancia",
         "label":""
      },
      {
         "source":"diversivo",
         "target":"usa-tlc",
         "label":""
      },
      {
         "source":"diversivo",
         "target":"riposare",
         "label":""
      },
      {
         "source":"lancia",
         "target":"lancia-cespugli",
         "label":""
      },
      {
         "source":"lancia",
         "target":"lancia-agente",
         "label":""
      },
      {
         "source":"lancia",
         "target":"riposare",
         "label":""
      },
      {
         "source":"lancia-cespugli",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"lancia-agente",
         "target":"resa",
         "label":""
      },
      {
         "source":"usa-tlc",
         "target":"riposare",
         "label":""
      },
      {
         "source":"usa-tlc",
         "target":"tlc-fail",
         "label":""
      },
      {
         "source":"usa-tlc",
         "target":"tlc-success",
         "label":""
      },
      {
         "source":"tlc-success",
         "target":"Continue-correre",
         "label":""
      },
      {
         "source":"tlc-fail",
         "target":"riposare",
         "label":""
      },
      {
         "source":"tlc-fail",
         "target":"tlc-success",
         "label":""
      }
   ]
}
module.exports = elements