import Cytoscape from 'cytoscape'

import _avsdf from 'cytoscape-avsdf'
import _klay from 'cytoscape-klay'
import _dagre from 'cytoscape-dagre';
const grid = {
    _: null,
    name: 'grid',
    fit: true,
    padding: 30,
    // animate: 'end',
}

const breadthfirst = {
    _: null,
    name: 'breadthfirst',
    fit: true,
    padding: 30,
    // animate: 'end',
}

const avsdf = {
    _: _avsdf,
    name: 'avsdf',
     fit: true,
     padding: 30,
    // animate: true,
}

const dagre = {
    _: _dagre,
    name: 'dagre',
     fit: true,
     padding: 30,
    // animate: true,
}

const klay = {
    _: _klay,
    name: 'klay',
    fit: true,
    padding: 30,
    // animate: 'end',
    // directed: true,
    // avoidOverlap: false,
    klay: {
        // Following descriptions taken from http://layout.rtsys.informatik.uni-kiel.de:9444/Providedlayout.html?algorithm=de.cau.cs.kieler.klay.layered
        addUnnecessaryBendpoints: false, // Adds bend points even if an edge does not change direction.
        aspectRatio: 1.6, // The aimed aspect ratio of the drawing, that is the quotient of width by height
        borderSpacing: 20, // Minimal amount of space to be left to the border
        compactComponents: false, // Tries to further compact components (disconnected sub-graphs).
        crossingMinimization: 'LAYER_SWEEP',
        direction: 'DOWN',
        nodeLayering: 'LONGEST_PATH',
        spacing: 50, // Overall setting for the minimal amount of space to be left between objects
        thoroughness: 20, // How much effort should be spent to produce a nice layout..
    },
}

const layouts = {
    grid,
    breadthfirst,
    avsdf,
    klay,
    dagre,
}

const setAndReturnLayoutName = (name) => {
    if (layouts[name]._){
      Cytoscape.use(layouts[name]._)
    }
    return {name: layouts[name].name}
}

export default setAndReturnLayoutName
