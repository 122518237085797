const elements = {
   nodes:[
      {
         "id":"1",
         "label":"1",
         "end":"",
         "iap":""
      },
      {
         "id":"istruzioni",
         "label":"istruzioni",
         "end":"",
         "iap":""
      },
      {
         "id":"P1",
         "label":"P1",
         "end":"",
         "iap":""
      },
      {
         "id":"P2",
         "label":"P2",
         "end":"",
         "iap":""
      },
      {
         "id":"P3",
         "label":"P3",
         "end":"",
         "iap":""
      },
      {
         "id":"P4",
         "label":"P4",
         "end":"",
         "iap":""
      },
      {
         "id":"P5",
         "label":"P5",
         "end":"",
         "iap":""
      },
      {
         "id":"P6",
         "label":"P6",
         "end":"",
         "iap":""
      },
      {
         "id":"P7",
         "label":"P7",
         "end":"",
         "iap":""
      },
      {
         "id":"P8",
         "label":"P8",
         "end":"",
         "iap":""
      },
      {
         "id":"P9",
         "label":"P9",
         "end":"",
         "iap":""
      },
      {
         "id":"P10",
         "label":"P10",
         "end":"",
         "iap":""
      },
      {
         "id":"P11",
         "label":"P11",
         "end":"",
         "iap":""
      },
      {
         "id":"P11-morte",
         "label":"P11-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P12",
         "label":"P12",
         "end":"",
         "iap":""
      },
      {
         "id":"P13",
         "label":"P13",
         "end":"",
         "iap":""
      },
      {
         "id":"P14",
         "label":"P14",
         "end":"",
         "iap":""
      },
      {
         "id":"P15",
         "label":"P15",
         "end":"",
         "iap":""
      },
      {
         "id":"P16",
         "label":"P16",
         "end":"",
         "iap":""
      },
      {
         "id":"P17",
         "label":"P17",
         "end":"",
         "iap":""
      },
      {
         "id":"P18",
         "label":"P18",
         "end":"",
         "iap":""
      },
      {
         "id":"P19",
         "label":"P19",
         "end":"",
         "iap":""
      },
      {
         "id":"P20",
         "label":"P20",
         "end":"",
         "iap":""
      },
      {
         "id":"P21",
         "label":"P21",
         "end":"",
         "iap":""
      },
      {
         "id":"P22",
         "label":"P22",
         "end":"",
         "iap":""
      },
      {
         "id":"P23",
         "label":"P23",
         "end":"",
         "iap":""
      },
      {
         "id":"P24",
         "label":"P24",
         "end":"",
         "iap":""
      },
      {
         "id":"P25",
         "label":"P25",
         "end":"",
         "iap":""
      },
      {
         "id":"P26",
         "label":"P26",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P27",
         "label":"P27",
         "end":"",
         "iap":""
      },
      {
         "id":"P28",
         "label":"P28",
         "end":"",
         "iap":""
      },
      {
         "id":"P29",
         "label":"P29",
         "end":"",
         "iap":""
      },
      {
         "id":"P30",
         "label":"P30",
         "end":"",
         "iap":""
      },
      {
         "id":"P31",
         "label":"P31",
         "end":"",
         "iap":""
      },
      {
         "id":"P32",
         "label":"P32",
         "end":"",
         "iap":""
      },
      {
         "id":"P33",
         "label":"P33",
         "end":"",
         "iap":""
      },
      {
         "id":"P34",
         "label":"P34",
         "end":"",
         "iap":""
      },
      {
         "id":"P35",
         "label":"P35",
         "end":"",
         "iap":""
      },
      {
         "id":"P36",
         "label":"P36",
         "end":"",
         "iap":""
      },
      {
         "id":"P36-morte",
         "label":"P36-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P37",
         "label":"P37",
         "end":"",
         "iap":""
      },
      {
         "id":"P38",
         "label":"P38",
         "end":"",
         "iap":""
      },
      {
         "id":"P38-risposta-errata-enigma",
         "label":"P38-risposta-errata-enigma",
         "end":"",
         "iap":""
      },
      {
         "id":"P38-soluzione",
         "label":"P38-soluzione",
         "end":"",
         "iap":""
      },
      {
         "id":"P39",
         "label":"P39",
         "end":"",
         "iap":""
      },
      {
         "id":"P40",
         "label":"P40",
         "end":"",
         "iap":""
      },
      {
         "id":"P41",
         "label":"P41",
         "end":"",
         "iap":""
      },
      {
         "id":"P42",
         "label":"P42",
         "end":"",
         "iap":""
      },
      {
         "id":"P42-morte",
         "label":"P42-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P43",
         "label":"P43",
         "end":"",
         "iap":""
      },
      {
         "id":"P43-morte",
         "label":"P43-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P44",
         "label":"P44",
         "end":"",
         "iap":""
      },
      {
         "id":"P45",
         "label":"P45",
         "end":"",
         "iap":""
      },
      {
         "id":"P46",
         "label":"P46",
         "end":"",
         "iap":""
      },
      {
         "id":"P47",
         "label":"P47",
         "end":"",
         "iap":""
      },
      {
         "id":"P48",
         "label":"P48",
         "end":"",
         "iap":""
      },
      {
         "id":"P48-morte",
         "label":"P48-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P49",
         "label":"P49",
         "end":"",
         "iap":""
      },
      {
         "id":"P50",
         "label":"P50",
         "end":"",
         "iap":""
      },
      {
         "id":"P51",
         "label":"P51",
         "end":"",
         "iap":""
      },
      {
         "id":"P51-morte",
         "label":"P51-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P52",
         "label":"P52",
         "end":"",
         "iap":""
      },
      {
         "id":"P53",
         "label":"P53",
         "end":"",
         "iap":""
      },
      {
         "id":"P54",
         "label":"P54",
         "end":"",
         "iap":""
      },
      {
         "id":"P54-morte",
         "label":"P54-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P55",
         "label":"P55",
         "end":"",
         "iap":""
      },
      {
         "id":"P56",
         "label":"P56",
         "end":"",
         "iap":""
      },
      {
         "id":"P57",
         "label":"P57",
         "end":"",
         "iap":""
      },
      {
         "id":"P57-morte",
         "label":"P57-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P58",
         "label":"P58",
         "end":"",
         "iap":""
      },
      {
         "id":"P59",
         "label":"P59",
         "end":"",
         "iap":""
      },
      {
         "id":"P60",
         "label":"P60",
         "end":"",
         "iap":""
      },
      {
         "id":"P61",
         "label":"P61",
         "end":"",
         "iap":""
      },
      {
         "id":"P61-morte",
         "label":"P61-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P62",
         "label":"P62",
         "end":"",
         "iap":""
      },
      {
         "id":"P63",
         "label":"P63",
         "end":"",
         "iap":""
      },
      {
         "id":"P63-morte",
         "label":"P63-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P64",
         "label":"P64",
         "end":"",
         "iap":""
      },
      {
         "id":"P65",
         "label":"P65",
         "end":"",
         "iap":""
      },
      {
         "id":"P66",
         "label":"P66",
         "end":"",
         "iap":""
      },
      {
         "id":"P67",
         "label":"P67",
         "end":"",
         "iap":""
      },
      {
         "id":"P67-morte",
         "label":"P67-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P68",
         "label":"P68",
         "end":"",
         "iap":""
      },
      {
         "id":"P69",
         "label":"P69",
         "end":"",
         "iap":""
      },
      {
         "id":"P70",
         "label":"P70",
         "end":"",
         "iap":""
      },
      {
         "id":"P71",
         "label":"P71",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P72",
         "label":"P72",
         "end":"",
         "iap":""
      },
      {
         "id":"P73",
         "label":"P73",
         "end":"",
         "iap":""
      },
      {
         "id":"P74",
         "label":"P74",
         "end":"",
         "iap":""
      },
      {
         "id":"P75",
         "label":"P75",
         "end":"",
         "iap":""
      },
      {
         "id":"P75-morte",
         "label":"P75-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P76",
         "label":"P76",
         "end":"",
         "iap":""
      },
      {
         "id":"P77",
         "label":"P77",
         "end":"",
         "iap":""
      },
      {
         "id":"P78",
         "label":"P78",
         "end":"",
         "iap":""
      },
      {
         "id":"P79",
         "label":"P79",
         "end":"",
         "iap":""
      },
      {
         "id":"P80",
         "label":"P80",
         "end":"",
         "iap":""
      },
      {
         "id":"P80-morte",
         "label":"P80-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P81",
         "label":"P81",
         "end":"",
         "iap":""
      },
      {
         "id":"P82",
         "label":"P82",
         "end":"",
         "iap":""
      },
      {
         "id":"P83",
         "label":"P83",
         "end":"",
         "iap":""
      },
      {
         "id":"P84",
         "label":"P84",
         "end":"",
         "iap":""
      },
      {
         "id":"P85",
         "label":"P85",
         "end":"",
         "iap":""
      },
      {
         "id":"P85-risposta-errata-enigma",
         "label":"P85-risposta-errata-enigma",
         "end":"",
         "iap":""
      },
      {
         "id":"P85-soluzione",
         "label":"P85-soluzione",
         "end":"",
         "iap":""
      },
      {
         "id":"P86",
         "label":"P86",
         "end":"",
         "iap":""
      },
      {
         "id":"P86-morte",
         "label":"P86-morte",
         "end":"negative",
         "iap":""
      },
      {
         "id":"P87",
         "label":"P87",
         "end":"",
         "iap":""
      },
      {
         "id":"P87-congratulazioni",
         "label":"P87-congratulazioni",
         "end":"positive",
         "iap":""
      }
   ],
   "edges":[
      {
         "source":"1",
         "target":"istruzioni",
         "label":""
      },
      {
         "source":"1",
         "target":"P1",
         "label":""
      },
      {
         "source":"istruzioni",
         "target":"1",
         "label":""
      },
      {
         "source":"istruzioni",
         "target":"P1",
         "label":""
      },
      {
         "source":"P1",
         "target":"P9",
         "label":""
      },
      {
         "source":"P1",
         "target":"P17",
         "label":""
      },
      {
         "source":"P2",
         "target":"P42",
         "label":""
      },
      {
         "source":"P2",
         "target":"P10",
         "label":""
      },
      {
         "source":"P3",
         "target":"P60",
         "label":""
      },
      {
         "source":"P4",
         "target":"P12",
         "label":""
      },
      {
         "source":"P4",
         "target":"P21",
         "label":""
      },
      {
         "source":"P4",
         "target":"P29",
         "label":""
      },
      {
         "source":"P5",
         "target":"P14",
         "label":""
      },
      {
         "source":"P5",
         "target":"P22",
         "label":""
      },
      {
         "source":"P6",
         "target":"P87",
         "label":""
      },
      {
         "source":"P7",
         "target":"P69",
         "label":""
      },
      {
         "source":"P7",
         "target":"P47",
         "label":""
      },
      {
         "source":"P8",
         "target":"P48",
         "label":""
      },
      {
         "source":"P8",
         "target":"P56",
         "label":""
      },
      {
         "source":"P9",
         "target":"P25",
         "label":""
      },
      {
         "source":"P9",
         "target":"P33",
         "label":""
      },
      {
         "source":"P9",
         "target":"P41",
         "label":""
      },
      {
         "source":"P10",
         "target":"P50",
         "label":""
      },
      {
         "source":"P10",
         "target":"P58",
         "label":""
      },
      {
         "source":"P11",
         "target":"P11-morte",
         "label":""
      },
      {
         "source":"P12",
         "target":"P61",
         "label":""
      },
      {
         "source":"P12",
         "target":"P69",
         "label":""
      },
      {
         "source":"P13",
         "target":"P30",
         "label":""
      },
      {
         "source":"P13",
         "target":"P38",
         "label":""
      },
      {
         "source":"P14",
         "target":"P31",
         "label":""
      },
      {
         "source":"P14",
         "target":"P22",
         "label":""
      },
      {
         "source":"P15",
         "target":"P87",
         "label":""
      },
      {
         "source":"P15",
         "target":"P69",
         "label":""
      },
      {
         "source":"P16",
         "target":"P72",
         "label":""
      },
      {
         "source":"P16",
         "target":"P80",
         "label":""
      },
      {
         "source":"P17",
         "target":"P25",
         "label":""
      },
      {
         "source":"P17",
         "target":"P33",
         "label":""
      },
      {
         "source":"P17",
         "target":"P41",
         "label":""
      },
      {
         "source":"P18",
         "target":"P66",
         "label":""
      },
      {
         "source":"P19",
         "target":"P52",
         "label":""
      },
      {
         "source":"P20",
         "target":"P4",
         "label":""
      },
      {
         "source":"P21",
         "target":"P61",
         "label":""
      },
      {
         "source":"P21",
         "target":"P69",
         "label":""
      },
      {
         "source":"P22",
         "target":"P39",
         "label":""
      },
      {
         "source":"P23",
         "target":"P69",
         "label":""
      },
      {
         "source":"P23",
         "target":"P7",
         "label":""
      },
      {
         "source":"P24",
         "target":"P85",
         "label":""
      },
      {
         "source":"P25",
         "target":"P49",
         "label":""
      },
      {
         "source":"P25",
         "target":"P57",
         "label":""
      },
      {
         "source":"P25",
         "target":"P33",
         "label":""
      },
      {
         "source":"P25",
         "target":"P41",
         "label":""
      },
      {
         "source":"P27",
         "target":"P25",
         "label":""
      },
      {
         "source":"P27",
         "target":"P33",
         "label":""
      },
      {
         "source":"P28",
         "target":"P5",
         "label":""
      },
      {
         "source":"P29",
         "target":"P69",
         "label":""
      },
      {
         "source":"P29",
         "target":"P21",
         "label":""
      },
      {
         "source":"P29",
         "target":"P46",
         "label":""
      },
      {
         "source":"P30",
         "target":"P38",
         "label":""
      },
      {
         "source":"P31",
         "target":"P22",
         "label":""
      },
      {
         "source":"P32",
         "target":"P61",
         "label":""
      },
      {
         "source":"P32",
         "target":"P69",
         "label":""
      },
      {
         "source":"P33",
         "target":"P65",
         "label":""
      },
      {
         "source":"P33",
         "target":"P25",
         "label":""
      },
      {
         "source":"P33",
         "target":"P41",
         "label":""
      },
      {
         "source":"P34",
         "target":"P74",
         "label":""
      },
      {
         "source":"P34",
         "target":"P82",
         "label":""
      },
      {
         "source":"P34",
         "target":"P3",
         "label":""
      },
      {
         "source":"P34",
         "target":"P11",
         "label":""
      },
      {
         "source":"P34",
         "target":"P19",
         "label":""
      },
      {
         "source":"P35",
         "target":"P44",
         "label":""
      },
      {
         "source":"P36",
         "target":"P36-morte",
         "label":""
      },
      {
         "source":"P37",
         "target":"P54",
         "label":""
      },
      {
         "source":"P37",
         "target":"P62",
         "label":""
      },
      {
         "source":"P37",
         "target":"P70",
         "label":""
      },
      {
         "source":"P38",
         "target":"P71",
         "label":""
      },
      {
         "source":"P38",
         "target":"P38-risposta-errata-enigma",
         "label":""
      },
      {
         "source":"P38",
         "target":"P27",
         "label":""
      },
      {
         "source":"P38-risposta-errata-enigma",
         "target":"P38-soluzione",
         "label":""
      },
      {
         "source":"P38-risposta-errata-enigma",
         "target":"P38",
         "label":""
      },
      {
         "source":"P38-soluzione",
         "target":"P38",
         "label":""
      },
      {
         "source":"P39",
         "target":"P8",
         "label":""
      },
      {
         "source":"P39",
         "target":"P16",
         "label":""
      },
      {
         "source":"P39",
         "target":"P24",
         "label":""
      },
      {
         "source":"P40",
         "target":"P79",
         "label":""
      },
      {
         "source":"P40",
         "target":"P32",
         "label":""
      },
      {
         "source":"P41",
         "target":"P73",
         "label":""
      },
      {
         "source":"P41",
         "target":"P81",
         "label":""
      },
      {
         "source":"P41",
         "target":"P25",
         "label":""
      },
      {
         "source":"P41",
         "target":"P33",
         "label":""
      },
      {
         "source":"P42",
         "target":"P42-morte",
         "label":""
      },
      {
         "source":"P43",
         "target":"P43-morte",
         "label":""
      },
      {
         "source":"P44",
         "target":"P36",
         "label":""
      },
      {
         "source":"P44",
         "target":"P28",
         "label":""
      },
      {
         "source":"P45",
         "target":"P53",
         "label":""
      },
      {
         "source":"P46",
         "target":"P69",
         "label":""
      },
      {
         "source":"P46",
         "target":"P21",
         "label":""
      },
      {
         "source":"P46",
         "target":"P23",
         "label":""
      },
      {
         "source":"P46",
         "target":"P55",
         "label":""
      },
      {
         "source":"P47",
         "target":"P87",
         "label":""
      },
      {
         "source":"P48",
         "target":"P48-morte",
         "label":""
      },
      {
         "source":"P49",
         "target":"P2",
         "label":""
      },
      {
         "source":"P49",
         "target":"P10",
         "label":""
      },
      {
         "source":"P49",
         "target":"P33",
         "label":""
      },
      {
         "source":"P49",
         "target":"P41",
         "label":""
      },
      {
         "source":"P50",
         "target":"P35",
         "label":""
      },
      {
         "source":"P50",
         "target":"P43",
         "label":""
      },
      {
         "source":"P50",
         "target":"P59",
         "label":""
      },
      {
         "source":"P51",
         "target":"P51-morte",
         "label":""
      },
      {
         "source":"P52",
         "target":"P13",
         "label":""
      },
      {
         "source":"P52",
         "target":"P37",
         "label":""
      },
      {
         "source":"P53",
         "target":"P77",
         "label":""
      },
      {
         "source":"P53",
         "target":"P85",
         "label":""
      },
      {
         "source":"P54",
         "target":"P54-morte",
         "label":""
      },
      {
         "source":"P55",
         "target":"P32",
         "label":""
      },
      {
         "source":"P55",
         "target":"P64",
         "label":""
      },
      {
         "source":"P55",
         "target":"P40",
         "label":""
      },
      {
         "source":"P56",
         "target":"P85",
         "label":""
      },
      {
         "source":"P57",
         "target":"P57-morte",
         "label":""
      },
      {
         "source":"P58",
         "target":"P51",
         "label":""
      },
      {
         "source":"P58",
         "target":"P67",
         "label":""
      },
      {
         "source":"P58",
         "target":"P75",
         "label":""
      },
      {
         "source":"P58",
         "target":"P83",
         "label":""
      },
      {
         "source":"P59",
         "target":"P44",
         "label":""
      },
      {
         "source":"P60",
         "target":"P52",
         "label":""
      },
      {
         "source":"P61",
         "target":"P61-morte",
         "label":""
      },
      {
         "source":"P62",
         "target":"P13",
         "label":""
      },
      {
         "source":"P63",
         "target":"P63-morte",
         "label":""
      },
      {
         "source":"P64",
         "target":"P69",
         "label":""
      },
      {
         "source":"P64",
         "target":"P7",
         "label":""
      },
      {
         "source":"P65",
         "target":"P18",
         "label":""
      },
      {
         "source":"P65",
         "target":"P25",
         "label":""
      },
      {
         "source":"P65",
         "target":"P41",
         "label":""
      },
      {
         "source":"P66",
         "target":"P84",
         "label":""
      },
      {
         "source":"P66",
         "target":"P76",
         "label":""
      },
      {
         "source":"P67",
         "target":"P67-morte",
         "label":""
      },
      {
         "source":"P68",
         "target":"P52",
         "label":""
      },
      {
         "source":"P69",
         "target":"P78",
         "label":""
      },
      {
         "source":"P69",
         "target":"P6",
         "label":""
      },
      {
         "source":"P69",
         "target":"P86",
         "label":""
      },
      {
         "source":"P70",
         "target":"P13",
         "label":""
      },
      {
         "source":"P72",
         "target":"P85",
         "label":""
      },
      {
         "source":"P73",
         "target":"P26",
         "label":""
      },
      {
         "source":"P73",
         "target":"P34",
         "label":""
      },
      {
         "source":"P73",
         "target":"P81",
         "label":""
      },
      {
         "source":"P73",
         "target":"P25",
         "label":""
      },
      {
         "source":"P73",
         "target":"P33",
         "label":""
      },
      {
         "source":"P74",
         "target":"P60",
         "label":""
      },
      {
         "source":"P74",
         "target":"P82",
         "label":""
      },
      {
         "source":"P74",
         "target":"P3",
         "label":""
      },
      {
         "source":"P74",
         "target":"P11",
         "label":""
      },
      {
         "source":"P74",
         "target":"P19",
         "label":""
      },
      {
         "source":"P75",
         "target":"P75-morte",
         "label":""
      },
      {
         "source":"P76",
         "target":"P28",
         "label":""
      },
      {
         "source":"P76",
         "target":"P84",
         "label":""
      },
      {
         "source":"P77",
         "target":"P63",
         "label":""
      },
      {
         "source":"P77",
         "target":"P85",
         "label":""
      },
      {
         "source":"P78",
         "target":"P47",
         "label":""
      },
      {
         "source":"P79",
         "target":"P15",
         "label":""
      },
      {
         "source":"P79",
         "target":"P69",
         "label":""
      },
      {
         "source":"P80",
         "target":"P80-morte",
         "label":""
      },
      {
         "source":"P81",
         "target":"P26",
         "label":""
      },
      {
         "source":"P82",
         "target":"P3",
         "label":""
      },
      {
         "source":"P82",
         "target":"P11",
         "label":""
      },
      {
         "source":"P82",
         "target":"P68",
         "label":""
      },
      {
         "source":"P82",
         "target":"P19",
         "label":""
      },
      {
         "source":"P83",
         "target":"P44",
         "label":""
      },
      {
         "source":"P84",
         "target":"P45",
         "label":""
      },
      {
         "source":"P84",
         "target":"P53",
         "label":""
      },
      {
         "source":"P85",
         "target":"P71",
         "label":""
      },
      {
         "source":"P85",
         "target":"P85-risposta-errata-enigma",
         "label":""
      },
      {
         "source":"P85",
         "target":"P20",
         "label":""
      },
      {
         "source":"P85-risposta-errata-enigma",
         "target":"P85-soluzione",
         "label":""
      },
      {
         "source":"P85-risposta-errata-enigma",
         "target":"P85",
         "label":""
      },
      {
         "source":"P85-soluzione",
         "target":"P85",
         "label":""
      },
      {
         "source":"P86",
         "target":"P86-morte",
         "label":""
      },
      {
         "source":"P87",
         "target":"P87-congratulazioni",
         "label":""
      }
   ]
}

module.exports = elements