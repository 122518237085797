const processTree = (tree) => {
	// console.log(tree)
    if (!tree) return
    tree.nodes = tree.nodes.map((node) => {
        return { data: node }
    })
	if (!tree.edges) return
    tree.edges = tree.edges.map((edge) => {
        return { data: edge }
    })
	// console.log(tree)
}

module.exports = processTree